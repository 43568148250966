import React from "react";
//import { Switch , Route} from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import './index.css';


import  Home  from "./MyComponents/Home";
import Navbar from "./MyComponents/Navbar";

import { Redirect } from 'react-router';



import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


const App = ()=> {
  return (
    <>

  
    
    <Router>
    <Navbar />
     
     <Switch>
        <Route exact path="/" component={Home} />
       
        
        <Redirect to="/" />
        
        
      </Switch>
     
      </Router>
      </> 
		//<h1>Welcome</h1>
     
  );
}

export default App;
