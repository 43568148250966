import React from "react";

import '../../src/MyComponents/index.css';

import {FaWhatsapp} from 'react-icons/fa';

import pulwama1 from "../../src/MyComponents/images/pul1.jpg";
import pulwama2 from "../../src/MyComponents/images/pul2.jpg";
import pulwama3 from "../../src/MyComponents/images/pul3.jpg";
import pulwama4 from "../../src/MyComponents/images/pul4.jpeg";
import pulwama5 from "../../src/MyComponents/images/pul5.jpeg";
import pulwama6 from "../../src/MyComponents/images/pul6.jpg";
import pulwama7 from "../../src/MyComponents/images/pul7.jpg";
import pulwama8 from "../../src/MyComponents/images/pul8.jpg";
import pulwama9 from "../../src/MyComponents/images/pul9.jpg";
import pulwama10 from "../../src/MyComponents/images/pul10.jpg";
import pulwama11 from "../../src/MyComponents/images/pul11.jpg";
import pulwama12 from "../../src/MyComponents/images/pul12.jpg";
import pulwama13 from "../../src/MyComponents/images/pul13.png";
import flame from "../../src/MyComponents/images/flame.gif";

const Home = () => {
	
	return (
	<>
	
	   <div id="heading">
	     <h1>14<superscript id="chotapuri">th</superscript> Feb 2019 Black day <span id="notheading"><br />( #PulwamaAttack )</span></h1>
		   </div>
 
	 <div className="container py-3 " >
	  <div className="row">
	    <div className="col max-auto">

		
		<div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
  <div class="carousel-inner">
    
	
	
	<div class="carousel-item active">
      <img src={pulwama1} class="d-block w-100" alt="..."/>
	  <div class="carousel-caption d-none d-md-block">
        <h5>Hacking Truth Present</h5>
        <p>मैं जला हुआ राख नहीं, अमर दीप हूं, जो मिट गया वतन पर मैं वो शहीद हूं। - भारत माता की जय</p>
      </div>
    </div>
	
	
    <div class="carousel-item">
      <img src={pulwama2} class="d-block w-100" alt="..."/>
    </div>
	
	
    <div class="carousel-item">
      <img src={pulwama3} class="d-block w-100" alt="..." />
    </div>
	
	
    <div class="carousel-item">
      <img src={pulwama4} class="d-block w-100" alt="..."/>
    </div>
	
	
    <div class="carousel-item">
      <img src={pulwama5} class="d-block w-100" alt="..." />
    </div>
	
	
    <div class="carousel-item">
      <img src={pulwama6} class="d-block w-100" alt="..."/>
    </div>
	
	
    <div class="carousel-item">
      <img src={pulwama7} class="d-block w-100" alt="..." />
    </div>
	
	
    <div class="carousel-item">
      <img src={pulwama8} class="d-block w-100" alt="..."/>
    </div>
	
	
    <div class="carousel-item">
      <img src={pulwama9} class="d-block w-100" alt="..." />
    </div>
	
	
    <div class="carousel-item">
      <img src={pulwama10} class="d-block w-100" alt="..."/>
    </div>
	
	
    <div class="carousel-item">
      <img src={pulwama11} class="d-block w-100" alt="..." />
    </div>
	
	
    <div class="carousel-item">
      <img src={pulwama12} class="d-block w-100" alt="..."/>
    </div>
	
	
    <div class="carousel-item">
      <img src={pulwama13} class="d-block w-100" alt="..." />
    </div>
	
	
	
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>



		
</div>		
  </div>		
    </div>		
	
	<div className="marquee">
	<marquee>The 2019 Pulwama attack occurred on 14 February 2019, when a convoy of vehicles carrying Indian security personnel on the Jammu–Srinagar National Highway was attacked by a vehicle-borne suicide bomber at Lethapora in the Pulwama district of the erstwhile state of Jammu and Kashmir.</marquee>
	
	</div>
	
	<div className="marquee">
<marquee>जय हिंद 
वीर जवानों को नमन😔😔😭😭   आदरणीय मित्रों! अगर आपको रास्ते में कोई सैनिक गाडी दिखाई दे जिस पर काला झंडा लगा हुआ हो तो कृपया हॉर्न न बजाए I उसमे आपके देश की रक्षा में अपने प्राणों की आहुति देने वाले वीर जवान का पार्थिव शरीर ले जाया जा रहा है I उसका सम्मान करे और ध्यान दे । ये जानकारी अपने अन्य सभी ग्रुप साथियो से शेयर करे जय हिंद। - Hacking Truth </marquee>
</div>
	
	<div className="marquee">
	<marquee>Who planned Pulwama attack?
The attack killed 40 Indian Central Reserve Police Force (CRPF) personnel as well as the perpetrator—Adil Ahmad Dar—who was a local Kashmiri youth from the Pulwama district. The responsibility for the attack was claimed by the Pakistan-based Islamist terrorist group, Jaish-e-Mohammed</marquee>
  </div>	
	
	<div className="container py-3">
	 <div className="row">
	   <div className="col max-auto">
	     <span><h3 align="center" class="text colorchange"  >भावपूर्ण &nbsp; 🙏 &nbsp; श्रधांजलि</h3></span>
		 
		 
		 <div className="flame-image img">
		 
		  <img src={flame} alt="flame" />
		 </div>
	   
	
	</div>
  	 </div>
	  </div>
	  
	  
	  
	   
	
	 <footer className=" text-center ">
	 <div className="wrap">
	 
	 <button className="btn btn-primary mb-3 social-media" ><a href="whatsapp://send?text=14th Feb 2019 - Black Day For India 🇮🇳 
	 
	 Let's pay heartfelt 🙏 tribute to our brave sons - Hacking Truth - https://pulwama.hackingtruth.org/" data-action="share/whatsapp/share">
	 Share via Whatsapp <FaWhatsapp /></a></button>
	 </div>

		
				
	 <p className="text-center" >&copy; 2022 <a href="https://www.hackingtruth.in" target="_blank" className="text-info"><u>Hacking Truth</u></a> | Cyber Security Company | All Rights Reserved | <a href="https://hackingtruth.org/Privacypolicy" target="_blank"  className="text-info"> <u>Privacy Policy</u></a> | Created by <a href="https://www.kumaratuljaiswal.in" target="_blank"  className="text-info"><u>Kumar Atul Jaiswal</u> </a></p>
    
	
		</footer>
	</>
	

	
 );
};


export default Home;